import PropTypes from 'prop-types';
import React from 'react';
import { imageAltTextParser } from '../utils/image';
import { isMobile } from '../utils/screenSize';

const TitleImage = ({ title, image, styles }) => {  
  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  const altText = imageAltTextParser(image?.src);

  // set height of div with aspect ratio of image on smaller screens
  if (hasMounted && isMobile()) {
    const height = (1 / image.aspectRatio) * 100;
    styles = {
      ...styles,
      height: `${height}vw`
    }  
  }

  return (
    <div
      className="full-width-image mt-0"
      style={{
        backgroundImage: `url(${image?.src})`,
        ...styles
      }}
      aria-label={altText}
    >
      <h1
        className="has-text-weight-bold is-size-4-mobile is-size-3-tablet is-size-2-widescreen has-text-primary beht-title"
      >
        {title}
      </h1>
    </div>
  )
}

TitleImage.propTypes = {
  title: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  styles: PropTypes.object
}

export default TitleImage
