import { graphql, Link, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import LinkButton from './LinkButton'

class BlogRoll extends React.Component {

  render() {
    const { data, displayTags, maxNumberPosts } = this.props
    let { edges: posts } = data.allMarkdownRemark

    if (displayTags && displayTags.length > 0 && posts) {
      posts = posts.filter((post) => {
        return post.node.frontmatter.tags.some((tag) => displayTags.includes(tag));
      });
    }

    if (!!maxNumberPosts) {
      posts = posts.slice(0, maxNumberPosts);
    }

    this.props.handlePostsCount(posts.length);

    return (
      <div className="columns is-multiline is-variable is-0-mobile is-4-desktop">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="is-parent column is-6" key={post.id}>
              <article
                className={`blog-list-item tile is-child box ${
                  post.frontmatter.featuredpost ? 'is-featured' : ''
                }`}
              >
                <header>
                  {post.frontmatter.featuredimage ? (
                    <div className="featured-thumbnail">
                      <figure className="image is-4by3">
                        <img src={post.frontmatter.featuredimage.childImageSharp.fluid.src} alt={`featured thumbnail for post ${post.frontmatter.title}`}/>
                      </figure>
                    </div>
                  ) : null}
                  <p className="post-meta">
                    <Link className="title has-text-primary is-size-4" to={post.fields.slug}>
                      {post.frontmatter.title}
                    </Link>
                    <span> &bull; </span>
                    <span className="subtitle is-size-5 is-block">
                      {post.frontmatter.date}
                    </span>
                  </p>
                </header>
                <p>
                  {post.excerpt}
                  <br />
                </p>
                <div className="has-text-centered">
                  <LinkButton text="Keep Reading →" link={post.fields.slug}/>
                </div>
              </article>
            </div>
          ))}
      </div>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  displayTags: PropTypes.array,
  handlePostsCount: PropTypes.func,
  maxNumberPosts: PropTypes.number
}

BlogRoll.defaultProps = {
  handlePostsCount: () => {},
  displayTags: []
}

export default ({displayTags, handlePostsCount, maxNumberPosts}) => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 300)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                tags
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} displayTags={displayTags} handlePostsCount={handlePostsCount} maxNumberPosts={maxNumberPosts}/>}
  />
)
