import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby';

const LinkButton = ({ text, link }) => {
  text = text || "Read More";
  
  return (
    <Link to={link}>
      <button className="button is-success is-outlined has-text-weight-bold">{text}</button>
    </Link>
  )
}

LinkButton.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
}

export default LinkButton
